import React, { useState, useRef, useEffect } from "react"
import { css } from "@emotion/react"
import { navigate, Link } from "gatsby"
import Layout from "../../components/layout"
import ContentContainer from "../../components/contentcontainer"
import { Row, Col, Form, Button, Spinner, InputGroup, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css" // muss für den Datepicker eingebunden werden
import de from 'date-fns/locale/de' // das ist für die Lokalisierung der Wochentage im Datepicker
import ImgBelly from "../../images/portfolio/belly.jpg"
import ImgNewborn from "../../images/portfolio/newborn.jpg"
import * as dateHelpers from '../../utils/datehelpers'
import SEO from "../../components/seo"


registerLocale('de', de) // das ist für die Lokalisierung der Wochentage im Datepicker


// ###############################
// ######### DateCard ############
// ###############################

const DateCard = props => (

  <Card className="text-center">
    <Card.Img variant="top" src={props.cardImage} />
    <Card.Body>
      <Card.Title>{props.title}</Card.Title>
      <div className="card-text mt-4">
        {props.children}
      </div>
      <Link to={props.buttonDetailsLinkTo} css={css`text-decoration: none; &:hover {text-decoration: none;}`}><Button variant="secondary" block className="mb-2">Details</Button></Link>
      <Link to={props.buttonAppointmentLinkTo} css={css`text-decoration: none; &:hover {text-decoration: none;}`}><Button variant="primary" block>Termin anfragen</Button></Link>
    </Card.Body>
  </Card>

)



// ###############################
// ###### EnterBirthdayForm ######
// ###############################

const EnterBirthdayForm = (props) => {

  const [currentSelectedDate, setCurrentSelectedDate] = useState(new Date());
  const [showSpinner, setShowSpinner] = useState(false);
  // um den DatePicker auch von aussen zu öffnen (nämlich von unserem Calender-Icon)
  // muss man etwas Krude mit einer ref arbeiten (ist eine Referenz auf die Komponente)
  // in der Hook-Variante wird dazu useRef verwendet
  // im DatePicker selbst wird dann die ref auf die hier definierte ref übergeben
  const datePickerRef = useRef(null);


  // handleBtnNextClick kümmert sich um den Klick auf Termin berechnen
  const handleBtnNextClick = () => {
    //setze showSpinner auf True, damit der Spinner angezeigt wird
    setShowSpinner(true)
    // nach einer gewissen Fake-Zeit zum nächsten Schritt gehen
    setTimeout(function () {
      // Die über die Props übergebene Callback-Funktion ausführen
      props.onNextStep(currentSelectedDate)
    }, 2000);
  }
  // handleBtnPrevClick kümmert sich um den Klick auf Zurück
  const handleBtnPrevClick = () => {
    props.onPrevStep()
  }

  // der useEffect setzt das readOnly Attribut auf true, damit sich auf mobile kein Screen Keyboard öffnet
  // das ist ein Workaround (aber leider best Practice)
  // Eigentlich würde man es direkt in der Prop über readOnly={true} definieren
  // aber dann würde sich der Kalender nicht mehr öffnen (weil der onClick handler außer Betrieb wäre)
  // also bleibt nur die Wahl, das über useEffect bei ComponentMont zu machen
  useEffect(() => {
    if (datePickerRef.current !== null) {
      datePickerRef.current.input.readOnly = true;
    }
  }, [datePickerRef]);


  return (
    <div className="contactUserFlowContainer text-center">
      <h4 className="mb-3">Bester Shootingtermin</h4>
      <p>Bitte gib unten den Geburtstag deines Kindes ein, falls das Kind schon auf der Welt ist. Alternativ kannst du den errechneten Geburtstermin (ET) eintragen.</p>
      <p>Wir berechnen auf Basis deiner Eingabe den bestmöglichen Termin für ein Shooting.</p>
      <Form>
        <Form.Group controlId="ReservationForm.preferredDate" className="mt-4">
          <InputGroup className="d-flex justify-content-center">

            <InputGroup.Prepend>
              {/* damit man ausserhalb auf das Kalendersymbol klicken kann und sich der Datepicker öffnet, müssen wir etwas Krude mit einer Ref arbeiten
              Dazu wird zuerst eine mit einem Hook useRef die datePickerRef definiert und dann eine "innere-Methode" der Referenz aufgerufen (setOpen); das current ist Teil der useRef-API */}
              <InputGroup.Text onClick={(e) => datePickerRef.current.setOpen(true)}><FontAwesomeIcon icon={faCalendar} size="1x" /></InputGroup.Text>
            </InputGroup.Prepend>
            {/* mit filterDate werden hier die Sonntage herausgefiltert
              damit mobile kein ScreenKeyboard eingeblendet wird, muss die readOnly Eigenschaft gesetzt werden
              sobald das CustomInput des DatePicker einen Fokus erhält; dafür benötigen wir einen State datePickerReadOnly
              background-color muss hier gesetzt werden, da readOnly sonst ausgegrautes Feld ist
              popperPlacement & popperModifiers -> damit der DatePicker *immer* unter dem Textfeld aufgeht
              Sonst ist springt das Feld in mobile
            */}
            <DatePicker
              selected={currentSelectedDate}
              onChange={date => setCurrentSelectedDate(date)}
              locale="de"
              dateFormat="dd.MM.yyyy"
              customInput={<Form.Control type="text" name="preferreddate" placeholder="date" />}
              className="text-center"
              ref={datePickerRef}
              css={css`&[readonly] {background-color: white;}`}
            />

          </InputGroup>
          <Form.Text className="text-muted">
            Geburtstag des Kindes bzw. errechneter Geburtstermin (ET)
          </Form.Text>
        </Form.Group>
      </Form>
      <Row>
        <Col>
          <Button onClick={handleBtnPrevClick} variant="primary" block className="mb-5 mt-4">Zurück</Button>
        </Col>
        <Col>
          <Button onClick={handleBtnNextClick} variant="primary" block className="mb-5 mt-4">
            {/* Zeige den Spinner nur an, wenn showSpinner = True;
            Zeige bei True das Wort "Termin" nicht an, damit das Layout von dem Button nicht umbricht (wäre mit Spinner zu gross) */}
            {showSpinner && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mr-2" />}
            {!showSpinner && <span>Termin</span>} berechnen
          </Button>
        </Col>
      </Row>
    </div>
  )
}




// ###############################
// #### CalucatedShootingDates ###
// ###############################

const CalucatedShootingDates = (props) => {

  var birthday = props.selectedDate;
  var bellyDaysToSubstractEarliest = 63
  var bellyDaysToSubstractLatest = 29
  var bellyEarlistShootingDate = dateHelpers.subsDays(birthday, bellyDaysToSubstractEarliest)
  var bellyLatestShootingDate = dateHelpers.subsDays(birthday, bellyDaysToSubstractLatest)
  var newbornDaysToAddEarliest = 10
  var newbornDaysToAddLatest = 14
  var newbornEarlistShootingDate = dateHelpers.addDays(birthday, newbornDaysToAddEarliest)
  var newbornLatestShootingDate = dateHelpers.addDays(birthday, newbornDaysToAddLatest)

  const badgeParent = css`
    position:relative;
    padding-top:20px;
    display:inline-block;
  `
  const badgeItem = css`
    position: absolute;
    right:-30px;
    top:0px;
    background: #a52f49;
    text-align: center;
    border-radius: 0.25rem;
    color:white;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    transform: rotate(35deg)
  `

  return (
    <div>
      <ContentContainer>
        <Row>

          <Col xs={12} sm={6} className="mb-4 d-flex">
            <DateCard
              title="Babybauch-Fotos"
              cardImage={ImgBelly}
              buttonDetailsLinkTo="/portfolio/belly/"
              buttonAppointmentLinkTo="/contact/reservation/"
            >
              <Row>
                <Col sm="12" md="6" className="offset-md-3">
                  <div className="shadow p-3 mb-4 bg-white rounded" css={badgeParent}>
                    <span css={badgeItem}>Best <br />Period</span>
                    {dateHelpers.formatDateToString(bellyEarlistShootingDate)} bis {dateHelpers.formatDateToString(bellyLatestShootingDate)}
                  </div>
                </Col>
              </Row>
            </DateCard>
          </Col>

          <Col xs={12} sm={6} className="mb-4 d-flex">
            <DateCard
              title="Newborn-Fotos"
              cardImage={ImgNewborn}
              buttonDetailsLinkTo="/portfolio/newborn/"
              buttonAppointmentLinkTo="/contact/reservation/"
            >
              <Row>
                <Col sm="12" md="6" className="offset-md-3">
                  <div className="shadow p-3 mb-4 bg-white rounded" css={badgeParent}>
                    <span css={badgeItem}>Best <br />Period</span>
                    {dateHelpers.formatDateToString(newbornEarlistShootingDate)} bis {dateHelpers.formatDateToString(newbornLatestShootingDate)}
                  </div>
                </Col>
              </Row>
            </DateCard>
          </Col>

        </Row>
      </ContentContainer>
    </div>
  )
}



// ###############################
// ######## Main Function ########
// ###############################

export default function CalculateDate() {
  const [step, setStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState();

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const onBtnCalucateDate = (selectedDateInChild) => {
    //console.log(selectedDateInChild)
    setSelectedDate(selectedDateInChild)
    nextStep()
  }

  const onBackBtn = () => {
    navigate('/contact/')
  }

  const onReservationButton = () => {
    navigate('/contact/reservation/')
  }



  return (
    <Layout>
      <SEO
        title="Wunschtermin | Babyfotografin Köln"
        description="Hier kannst du direkt den besten Termin für dein Shooting bestimmen und deinen Wunschtermin reservieren. Oder du schreibst mir einfach und wir besprechen alles weitere."
      />
      {step === 0 && <EnterBirthdayForm onNextStep={(selectedDateInChild) => onBtnCalucateDate(selectedDateInChild)} onPrevStep={onBackBtn} />}
      {step === 1 && <CalucatedShootingDates onNextStep={onReservationButton} onPrevStep={prevStep} selectedDate={selectedDate} />}
    </Layout>
  )
}


